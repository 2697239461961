

import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';

const BalaceDetails = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch transactions from API
    const fetchTransactions = async () => {
        try {
            const response = await axiosInstance.get(`account/api/v1/agency-wallet-transaction/`);
            setTransactions(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching transactions:', err);
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading transactions.</div>;
    }

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Wallet Transactions</h2>
            <div className="max-h-[400px] overflow-y-auto ">
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Date</th>
                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Description</th>
                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Amount</th>
                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Type</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction) => (
                        <tr key={transaction.id} >
                            <td className="inter-light whitespace-nowrap py-3 text-center text-[12px] text-black tracking-wider">
                                {new Date(transaction.created_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                })}
                            </td>
                            <td className="inter-light  whitespace-nowrap  py-3 text-center text-[12px] text-black  tracking-wider ">{transaction.description}</td>
                            <td className={`inter-light whitespace-nowrap py-3 text-center text-[12px] tracking-wider ${transaction.debit ? 'text-red-500' : 'text-green-500'}`}>
                                {transaction.amount.toLocaleString()}
                            </td>
                            <td className={`inter-light whitespace-nowrap py-3 text-center text-[12px] tracking-wider ${transaction.debit ? 'text-red-500' : 'text-green-500'
                                }`}>
                                {transaction.debit ? 'Debit' : 'Credit'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            </div>
           
        </div>
    );
};




export default BalaceDetails