


import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import AddStaffForm from './AddStaffForm';
import { AnimatePresence } from 'framer-motion';
import UpdateStaff from './UpdateStaff';
import { Link } from 'react-router-dom';
import SourceAddForm from '../Source/SourceAddForm';
import SourceUpdateForm from '../Source/SourceUpdateForm';


const AgentStaffListingPage = ({ menus }) => {
    const [isAddStaffVisible, setIsAddStaffVisible] = useState(false);
    const [isAddStaffUpdate, setIsAddStaffUpdate] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [staffData, setStaffData] = useState([])
    const [activeTab, setActiveTab] = useState('user');

    const toggleUpdateStaffVisible = () => {
        setIsAddStaffUpdate(!isAddStaffUpdate);
    };

    const toggleAddStaffVisibility = () => {
        setIsAddStaffVisible(!isAddStaffVisible);
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('account/api/v1/register-agency-staff/');
            setData(response.data);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchStaffDetails = async (id) => {
        try {
            const response = await axiosInstance.get(`account/api/v1/agency-staff-detail-view/${id}/`);
            setStaffData(response.data);
            console.log(response.data, "second data");
        } catch (error) {
            console.error('Error fetching staff details:', error);
        }
    };
    const handleEditClick = (id) => {
        fetchStaffDetails(id);
        toggleUpdateStaffVisible();
       
    };

    const [showSource, setShowSource] = useState(false);
    const [sources, setSources] = useState([]);
    const [getSource, setGetSource] = useState(null);
    const [updateSource, setUpdateSource] = useState(false);


    const togglehandle = () => {
        setShowSource(!showSource);
    };

    const fetchSources = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/source_list_create/');
            setSources(response.data);
        } catch (error) {
            console.error('Error fetching sources:', error);
        }
    };

    useEffect(() => {
        fetchSources();
    }, []);

    const handleButtonClick = async (id) => {
        try {
            const response = await axiosInstance.get(`/kanban/api/v1/source_detail_update/${id}/`);
            setGetSource(response.data);
            setUpdateSource(true);
        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };
    return (
        <div className="relative top-[-140px] flex flex-col gap-4 justify-center  w-full  ">
            <div className='flex justify-center items-center'>
                <div className="bg-gray-50 rounded-md flex justify-center ">
                    <div className="inter-font px-4 flex gap-20 whitespace-nowrap py-4 text-center text-[11px] text-gray-400 w-full" style={{ borderBottom: '1px solid rgba(128, 128, 128, 0.5)', width: '100%' }}>
                        <div className={`text-black cursor-pointer ${activeTab === 'user' ? 'text-black font-bold  ' : 'text-gray-400'}`} onClick={() => setActiveTab('user')}>
                            User
                        </div>
                        {menus && menus.source ? (
                            <div className={`text-black cursor-pointer ${activeTab === 'source' ? 'text-black font-bold  ' : 'text-gray-400'}`} onClick={() => setActiveTab('source')}>
                                Source
                            </div>
                        ) : (
                            <div className={`text-black ${activeTab === 'source' ? 'text-black font-bold' : 'text-gray-400'}`} >
                                Source
                            </div>
                        )}
                        <div>Department</div>
                        <div>Stages</div>
                    </div>
                </div>
            </div>






            {/* user */}
            {
                activeTab === 'user' && (
                    <div className="flex justify-center items-center w-full ">
                        <div className="overflow-x-auto  flex justify-center items-center flex-col gap-4">
                            <div className="flex justify-center items-center">
                                {/* header */}
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                #
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Created At
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Name
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Email
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Phone Number
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Designation
                                            </th>
                                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                Visibility
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="space-y-4">
                                        {data.slice(0, 5).map((item, index) => (
                                            <tr key={index} className="table-row border-b-2 border-gray-100 bg-white shadow-lg py-6">
                                                <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider">
                                                    {index + 1}
                                                </td>
                                                <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider">
                                                    {item.created_at}
                                                </td>
                                                <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider flex justify-center items-center">
                                                    <div className="w-8 h-8 rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src="profile.jpg" alt="Profile" />
                                                    </div>
                                                    <div>{item.first_name}</div>
                                                </td>
                                                <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider">
                                                    {item.email}
                                                </td>
                                                <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider">
                                                    {item.phone}
                                                </td>
                                                <td className="inter-font whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider">
                                                    {item.designation}
                                                </td>
                                                <td className="inter-font whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider font-semibold">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleEditClick(item.id)}
                                                        className="bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 text-[10px] px-16 py-1 text-center rounded-md dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {data.length < 5 && (
                                <div className="flex justify-center items-center inter-font">
                                    <button
                                        type="button"
                                        onClick={toggleAddStaffVisibility}
                                        className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                                    >
                                        Add Staff
                                    </button>
                                </div>
                            )}

                                <AnimatePresence>
                                    {isAddStaffVisible && <AddStaffForm setIsAddStaffVisible={setIsAddStaffVisible} fetchData={fetchData}  />}
                                </AnimatePresence>
                                <AnimatePresence>
                                    {isAddStaffUpdate && <UpdateStaff setIsAddStaffUpdate={setIsAddStaffUpdate} staffData={staffData} setStaffData={setStaffData} fetchData={fetchData} />}
                                </AnimatePresence>
                        </div>

                    </div>
                )
            }

            {/* source */}
            {
                menus.source && activeTab === 'source' && (
                    <div>
                        <div className=' '>
                            <div className='w-full'>
                                <div className="px-20 w-full">
                                    <div className="py-2">
                                        <div className="flex bg-gray-100 justify-between px-20 py-2 items-center">
                                            <div>
                                                <span className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                                    Sources
                                                </span>
                                            </div>
                                            <div className="flex gap-4">
                                                <button
                                                    className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 font-bold focus:outline-none focus:ring-black-300 text-[10px] px-16 py-1 text-center dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                                                    type="button"
                                                    onClick={togglehandle}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {sources.map((source, index) => (
                                        <div key={index} className="py-2">
                                            <div className="flex bg-white justify-between px-20 py-2 rounded-md shadow-md">
                                                <div>
                                                    <span className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">
                                                        {index + 1}
                                                    </span>
                                                    <span className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">
                                                        {source.name}
                                                    </span>
                                                </div>
                                                <div className="flex gap-4">
                                                    <button
                                                        className="text-black-600 bg-black text-white border font-bold border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 text-[10px] px-16 py-1 text-center dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                                                        type="button"
                                                        onClick={() => handleButtonClick(source.id)}
                                                    >
                                                        Edit
                                                    </button>
                                                    {/* <span onClick={() => handleButtonClick(source.id)}>
                                            <EditIcon style={{ color: 'green', cursor: 'pointer' }} /></span>
                                        <span><DeleteIcon style={{ color: 'red', cursor: 'pointer' }} /></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='absolute'>
                                <AnimatePresence>
                                    {updateSource && (
                                        <SourceUpdateForm
                                            getSource={getSource}
                                            setGetSource={setGetSource}
                                            updateSource={updateSource}
                                            setUpdateSource={setUpdateSource}
                                            fetchSources={fetchSources}
                                        />
                                    )}
                                </AnimatePresence>
                                <AnimatePresence>
                                    <div>
                                        {showSource && (
                                            <SourceAddForm showSource={showSource} setShowSource={setShowSource} fetchSources={fetchSources} />
                                        )}
                                    </div>
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>





    );
};

export default AgentStaffListingPage;






