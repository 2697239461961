
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import AddUser from '../Forms/AddUser';
import UpdateUser from '../Update/UpdateUser';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AnimatePresence } from 'framer-motion';
import AddStaff from '../Forms/AddStaff';
import UpdateStaff from '../Update/UpdateStaff';


const UserListingPage = ({ menus }) => {
    const [isCategoryOpen, setIsCategoryOpen] = useState(false)
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDisignationOpen, setIsDesignationOpen] = useState(false);
    const [isAddUserVisible, setIsAddUserVisible] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [AddStaffModal, setAddStaffModal] = useState(false);
    const [updateStaffModal, setUpdateStaffModal] = useState(false);
    const [staff, setStaff] = useState([]);
    const [activeTab, setActiveTab] = useState('associate');
    const [companySettings, setCompanySettings] = useState(null);
    const [clientFee, setClientFee] = useState('');



    const [nextUrl, setNextUrl] = useState(null);

    const categoryDropdownRef = useRef(null);
    const destinationDropdownRef = useRef(null);
    const fetchCompanySettings = async () => {
        try {
            const response = await axiosInstance.get('/account/api/v1/company-settings/');
            setClientFee(response.data.client_fee);
            setCompanySettings(response.data);
        } catch (error) {
            console.error('Error fetching company settings:', error);
        }
    };
    useEffect(() => {
        fetchCompanySettings();
    }, []);


    const handleClickOutside = (event) => {

        if (
            categoryDropdownRef.current &&
            !categoryDropdownRef.current.contains(event.target)
        ) {
            setIsCategoryOpen(false);
        }
        if (
            destinationDropdownRef.current &&
            !destinationDropdownRef.current.contains(event.target)
        ) {
            setIsDesignationOpen(false);
        }

    };


    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);


        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //  __________________________________________________________________________  fetchstaffdata


    const fetchStaffData = async (reset = true) => {

        console.log("fetch data", reset);
        setLoading(true);
        const offset = reset ? 0 : staff.length;
        const url = `/account/api/v1/users/?limit=10&offset=${offset}`;

        try {
            const response = await axiosInstance.get(url);

            const filteredStaff = response.data.results.filter(user => user.is_staff === true);
            console.log(filteredStaff,"hello staff");
            
            setStaff(prevData => (reset ? filteredStaff : [...prevData, ...filteredStaff]))

            setNextUrl(response.data.next); 
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && nextUrl) {
                fetchStaffData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [nextUrl, loading]);

    useEffect(() => {
        fetchStaffData();
    }, []);






    // __________________________________________________



    const fetchData = async (reset = true) => {

        console.log("fetch data", reset);
        setLoading(true);
        const url = reset ? '/account/api/v1/users/?limit=10&offset=0' : nextUrl;

        try {
            const response = await axiosInstance.get(url);
            // const filteredClients = response.data.results.filter(item => item.is_agency === true);

            const filteredClients = response.data.results.filter(item => item.is_agency === true);
            console.log("Filtered Clients:", filteredClients);

            // Update data correctly
            setData(prevData => (reset ? filteredClients : [...prevData, ...filteredClients]));

            setNextUrl(response.data.next);

        } catch (error) {
            console.error('Error fetching users:', error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 &&
                nextUrl &&
                !loading
            ) {
                fetchData(false); // Fetch next set of data
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [nextUrl, loading]);


    useEffect(() => {
        fetchData();
    }, []);





    useEffect(() => {
        setModalVisible(false);
    }, []);

    const handleCloseModal = () => {
        setSelectedUserData(null);
        setModalVisible(false);
    };



    const toggleAddUserVisibility = () => {
        setIsAddUserVisible(!isAddUserVisible);
    };
    const toggleAddStaffVisibility = () => {
        setAddStaffModal(!AddStaffModal);
    };

    const handleClose = () => {
        setIsAddUserVisible(false);
    };

    const toggleListCategory = (itemId) => {
        setIsCategoryOpen(itemId === isCategoryOpen ? null : itemId);
    };

    const toggleListDesignation = (itemId) => {
        setIsDesignationOpen(itemId === isDisignationOpen ? null : itemId);
    };

    const handleCategoryChange = async (categoryID, user) => {
        try {
            console.log("client", user);
            await axiosInstance.patch(`/account/api/v1/user-detail-update/${user.id}/`, { 'category': categoryID });
            console.log('Category updated successfully');
            setIsCategoryOpen(false);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleDesignationChange = async (designationID, user) => {
        try {
            console.log("user", user);
            await axiosInstance.patch(`/account/api/v1/user-detail-update/${user.id}/`, { 'designation': designationID });
            console.log('designation updated successfully');
            setIsDesignationOpen(false);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };



    const [options, setOptions] = useState({
        categories: [],
        designations: [],
    });

    const handleButtonClick = async (id) => {
        try {
            const response = await axiosInstance.get(`/account/api/v1/user-detail-update/${id}/`);
            setSelectedUserData(response.data);
            console.log(response.data, "oooiiii abhi");
            setModalVisible(true);
        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };


    const handleStaffClick = async (id) => {
        try {
            const response = await axiosInstance.get(`/account/api/v1/user-detail-update/${id}/`);
            setSelectedUserData(response.data);
            console.log(response.data, "oooiiii abhi");
            setUpdateStaffModal(true);
        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    categories: categoriesResponse.data
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        const fetchDesignationOptions = async () => {
            try {
                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    designations: designationsResponse.data
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
        fetchDesignationOptions();



    }, []);
    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.image_url; // Return the URL if successful
        } catch (error) {
            console.error('Error uploading image:', error);
            return null; // Return null if an error occurs
        }
    };
    const handleInputChange = async (e, key) => {
        if (key === 'client_fee') {
            setClientFee(e.target.value);
        } else if (e.target.files) {
            const file = e.target.files[0];
            if (file) {
                const uploadedUrl = await uploadImage(file);
                if (uploadedUrl) {
                    setCompanySettings((prev) => ({
                        ...prev,
                        [key]: uploadedUrl,
                    }));
                }
            }
        }
    };
    const updateCompanySettings = async () => {
        setLoading(true);
        try {
            const updatedData = {
                client_fee: clientFee,
                web_banner_img: companySettings.web_banner_img,
                mobile_banner_img: companySettings.mobile_banner_img,
                web_logo: companySettings.web_logo,
                mobile_logo: companySettings.mobile_logo,
            };

            await axiosInstance.patch('/account/api/v1/company-settings/', updatedData);
            alert('Company settings updated successfully!');
        } catch (error) {
            console.error('Error updating company settings:', error);
            alert('Failed to update company settings.');
        } finally {
            setLoading(false);
        }
    };




    return (
        <div className='flex flex-col w-full pt-4  gap-6 relative z-0  '>
            <div>
                <div className="bg-gray-50 rounded-md flex justify-center">
                    <div
                        className="inter-font px-4 flex gap-20 whitespace-nowrap pt-6 text-center text-[11px] text-gray-400 w-full"
                        style={{ borderBottom: "1px solid rgba(128, 128, 128, 0.5)", width: "100%" }}
                    >
                        <div
                            className={`cursor-pointer pb-2 ${activeTab === "associate" ? "text-black border-b-4  border-green-500" : ""
                                }`}
                            onClick={() => setActiveTab("associate")}
                        >
                            Associate
                        </div>
                        <div
                            className={`cursor-pointer pb-2 ${activeTab === "staff" ? "text-black border-b-4 border-green-500" : ""
                                }`}
                            onClick={() => setActiveTab("staff")}
                        >
                            Staff
                        </div>
                        <div
                            className={`cursor-pointer pb-2 ${activeTab === "companysettings" ? "text-black border-b-4 border-green-500" : ""
                                }`}
                            onClick={() => setActiveTab("companysettings")}
                        >
                            Company Settings
                        </div>
                        <div
                        // className={`cursor-pointer pb-2 ${activeTab === "department" ? "text-black border-b-4 border-green-500" : ""
                        //     }`}
                        // onClick={() => setActiveTab("department")}
                        >
                            Department
                        </div>
                        <div
                        // className={`cursor-pointer pb-2 ${activeTab === "stages" ? "text-black border-b-4 border-green-500" : ""
                        //     }`}
                        // onClick={() => setActiveTab("stages")}
                        >
                            Stages
                        </div>
                    </div>
                </div>
            </div>

            {/* first section */}
            {activeTab === 'associate' && (


                <div>
                    <div className='overflow-x-auto flex-grow h-[400px]  '>
                        <table className=" w-full divide-y divide-gray-200 bg-gray-50  ">
                            <thead>
                                <tr>
                                    <th className="inter-font whitespace-nowrap px-4 py-3  text-left text-[11px] text-gray-400">#</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left  text-[11px] text-gray-400">Logo</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Created at</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Name</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Email ID</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Balance</th>

                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Phone Number</th>
                                    {/* <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Designation</th>
                                <th className="inter-font flex justify-center items-center whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400 ">
                                    Category
                                    <span ><ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} /></span>
                                </th> */}
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left  text-[11px] text-gray-400">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className="border-b-2 border-gray-100 bg-white">
                                        <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{index + 1}</td>
                                        <td className="inter-light whitespace-nowrap px-4 py-3 text-center">
                                            <img
                                                src={item.profile_img}
                                                alt="Logo"
                                                className="w-10 h-10 object-cover rounded-full"
                                            />
                                        </td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.created_at}</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.full_name}</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.email}</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.wallet_balance
                                        }</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.phone}</td>
                                        {/* <td className='relative'>
                                        <div className="flex justify-center">
                                            <button
                                                onClick={() => toggleListDesignation(item.id)}
                                                style={{
                                                    borderRadius: '60px', fontSize: '11px', display: 'flex', alignItems: "center", justifyContent: "center", width: '70px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                }}
                                            >
                                                <div className='inter-light' style={{ color: 'black', fontSize: '12px' }}>
                                                    {item.designation ? item.designation.name : "Select designation"}
                                                </div>
                                            </button>
                                            {isDisignationOpen === item.id && (
                                                <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={destinationDropdownRef}>
                                                    <ul className="py-2">
                                                        {loading ? (
                                                            <li>Loading...</li>
                                                        ) : error ? (
                                                            <li>Error: {error.message}</li>
                                                        ) : (
                                                            options.designations.map((designation) => (
                                                                <li
                                                                    key={designation.id}
                                                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                    onClick={() => handleDesignationChange(designation.id, item)}
                                                                >
                                                                    <div className='flex justify-start items-center gap-1'>
                                                                        <div style={{ width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                        <div className='inter-font'>{designation.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className='relative'>
                                        <div className="flex justify-center">
                                            <button
                                                onClick={() => toggleListCategory(item.id)}
                                                style={{
                                                    backgroundColor: `#${item.category?.bg_color}`, borderRadius: '60px', fontSize: '12px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                }}
                                            >
                                                <div className='inter-light' style={{ color: `#${item.category?.color_code}`, fontSize: '12px' }}>
                                                    {item.category ? item.category.name : "Select Category"}
                                                </div>
                                            </button>
                                            {isCategoryOpen === item.id && (
                                                <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={categoryDropdownRef}>
                                                    <ul className="py-2">
                                                        {loading ? (
                                                            <li>Loading...</li>
                                                        ) : error ? (
                                                            <li>Error: {error.message}</li>
                                                        ) : (
                                                            options.categories.map((category) => (
                                                                <li
                                                                    key={category.id}
                                                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                    onClick={() => handleCategoryChange(category.id, item)}
                                                                >
                                                                    <div className='flex justify-start items-center gap-1'>
                                                                        <div style={{ backgroundColor: `#${category.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                        <div className='inter-font'>{category.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td> */}

                                        <td className="inter-font whitespace-nowrap  py-3  text-[10px] text-black tracking-wider font-semibold">
                                            <button
                                                type="button"
                                                onClick={() => handleButtonClick(item.id)}
                                                className="  text-blue-500   text-[11px] px-2 py-1 text-center  underline"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleButtonClick(item.id)}
                                                className="  text-red-500   text-[11px] px-2 py-1 text-center  underline"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className='flex justify-center items-center inter-font py-4 '>
                        <button
                            type="button"
                            onClick={toggleAddUserVisibility}
                            className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                        >
                            Add Associate
                        </button>
                    </div>

                </div>
            )}


            {/* // second section */}

            {activeTab === 'staff' && (
                <div>
                    <div className='overflow-x-auto flex-grow h-[400px] '>
                        <table className="w-full divide-y divide-gray-200 bg-gray-50   ">
                            <thead>
                                <tr>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">#</th>

                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Created at</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Name</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Email ID</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Phone Number</th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Designation</th>
                                    <th className="inter-font flex justify-center text-left whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400 ">
                                        Category
                                        <span ><ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} /></span>
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {staff.map((item, index) => (
                                    <tr key={index} className="border-b-2 border-gray-100 bg-white">
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{index + 1}</td>

                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.created_at}</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.full_name}</td>
                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.email}</td>

                                        <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">{item.phone}</td>
                                        <td className='relative'>
                                            <div className="flex ">
                                                <button
                                                    onClick={() => toggleListDesignation(item.id)}
                                                    style={{
                                                        borderRadius: '60px', fontSize: '11px', display: 'flex', alignItems: "center", justifyContent: "center", width: '70px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                    }}
                                                >
                                                    <div className='inter-light' style={{ color: 'black', fontSize: '12px' }}>
                                                        {item.designation ? item.designation.name : "Select designation"}
                                                    </div>
                                                </button>
                                                {isDisignationOpen === item.id && (
                                                    <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={destinationDropdownRef}>
                                                        <ul className="py-2">
                                                            {loading ? (
                                                                <li>Loading...</li>
                                                            ) : error ? (
                                                                <li>Error: {error.message}</li>
                                                            ) : (
                                                                options.designations.map((designation) => (
                                                                    <li
                                                                        key={designation.id}
                                                                        className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                        onClick={() => handleDesignationChange(designation.id, item)}
                                                                    >
                                                                        <div className='flex justify-start items-center gap-1'>
                                                                            <div style={{ width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                            <div className='inter-font'>{designation.name}</div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className='relative'>
                                            <div className="flex ">
                                                <button
                                                    onClick={() => toggleListCategory(item.id)}
                                                    style={{
                                                        backgroundColor: `#${item.category?.bg_color}`, borderRadius: '60px', fontSize: '12px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                    }}
                                                >
                                                    <div className='inter-light' style={{ color: `#${item.category?.color_code}`, fontSize: '12px' }}>
                                                        {item.category ? item.category.name : "Select Category"}
                                                    </div>
                                                </button>
                                                {isCategoryOpen === item.id && (
                                                    <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={categoryDropdownRef}>
                                                        <ul className="py-2">
                                                            {loading ? (
                                                                <li>Loading...</li>
                                                            ) : error ? (
                                                                <li>Error: {error.message}</li>
                                                            ) : (
                                                                options.categories.map((category) => (
                                                                    <li
                                                                        key={category.id}
                                                                        className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                        onClick={() => handleCategoryChange(category.id, item)}
                                                                    >
                                                                        <div className='flex justify-start items-center gap-1'>
                                                                            <div style={{ backgroundColor: `#${category.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                            <div className='inter-font'>{category.name}</div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </td>



                                        <td className="inter-font whitespace-nowrap  py-3  text-[10px] text-black tracking-wider font-semibold">
                                            <button
                                                type="button"
                                                onClick={() => handleStaffClick(item.id)}
                                                className="  text-blue-500   text-[11px] px-2 py-1 text-center  underline"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleButtonClick(item.id)}
                                                className="  text-red-500   text-[11px] px-2 py-1 text-center  underline"
                                            >
                                                Delete
                                            </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className='flex justify-center items-center inter-font py-4 '>
                        <button
                            type="button"
                            onClick={toggleAddStaffVisibility}
                            className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                        >
                            Add Staff
                        </button>
                    </div>

                </div>

            )}

            <div>
                {activeTab === 'companysettings' && companySettings && (
                    <div className="p-6">
                        <div className="grid grid-cols-2 gap-6">
                            {/* Web Banner */}
                            <div>
                                <label htmlFor="webBanner" className="block mb-2 text-[13px] inter-font">
                                    Web Banner
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor="webBanner"
                                        className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                                    >
                                        {companySettings.web_banner_img ? (
                                            <img
                                                src={companySettings.web_banner_img}
                                                alt="Web Banner"
                                                className="h-full w-full object-contain rounded-md px-2 py-2"
                                            />
                                        ) : (
                                            <div className="text-gray-500 text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-10 h-10 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <p>Click to upload web banner</p>
                                            </div>
                                        )}
                                        <input
                                            id="webBanner"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={(e) => handleInputChange(e, 'web_banner_img')}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Mobile Banner */}
                            <div>
                                <label htmlFor="mobileBanner" className="block mb-2 text-[13px] inter-font">
                                    Mobile Banner
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor="mobileBanner"
                                        className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                                    >
                                        {companySettings.mobile_banner_img ? (
                                            <img
                                                src={companySettings.mobile_banner_img}
                                                alt="Mobile Banner"
                                                className="h-full w-full object-contain rounded-md px-2 py-2"
                                            />
                                        ) : (
                                            <div className="text-gray-500 text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-10 h-10 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <p>Click to upload mobile banner</p>
                                            </div>
                                        )}
                                        <input
                                            id="mobileBanner"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={(e) => handleInputChange(e, 'mobile_banner_img')}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Web Logo */}
                            <div>
                                <label htmlFor="webLogo" className="block mb-2 text-[13px] inter-font">
                                    Web Logo
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor="webLogo"
                                        className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                                    >
                                        {companySettings.web_logo ? (
                                            <img
                                                src={companySettings.web_logo}
                                                alt="Web Logo"
                                                className="h-full w-full object-contain rounded-md px-2 py-2"
                                            />
                                        ) : (
                                            <div className="text-gray-500 text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-10 h-10 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <p>Click to upload web logo</p>
                                            </div>
                                        )}
                                        <input
                                            id="webLogo"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={(e) => handleInputChange(e, 'web_logo')}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Mobile Logo */}
                            <div>
                                <label htmlFor="mobileLogo" className="block mb-2 text-[13px] inter-font">
                                    Mobile Logo
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor="mobileLogo"
                                        className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                                    >
                                        {companySettings.mobile_logo ? (
                                            <img
                                                src={companySettings.mobile_logo}
                                                alt="Mobile Logo"
                                                className="h-full w-full object-contain rounded-md px-2 py-2"
                                            />
                                        ) : (
                                            <div className="text-gray-500 text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-10 h-10 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <p>Click to upload mobile logo</p>
                                            </div>
                                        )}
                                        <input
                                            id="mobileLogo"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={(e) => handleInputChange(e, 'mobile_logo')}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Client Fee Input */}
                            <div>
                                <label className="block text-[13px] inter-font text-gray-700">
                                    Client Fee
                                </label>
                                <input
                                    type="number"
                                    value={clientFee}
                                    onChange={(e) => setClientFee(e.target.value)}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* Save Button */}
                        <div className="flex justify-end px-4 py-4">
                            <button
                                onClick={updateCompanySettings}
                                disabled={loading}
                                className="px-4 py-2 bg-green-500 text-black text-[13px] rounded-md inter-font"
                            >
                                {loading ? 'Saving...' : 'Save Changes'}
                            </button>
                        </div>
                    </div>
                )}
            </div>













            <AnimatePresence>

                {isAddUserVisible && (
                    <div className="inset-0 z-50 flex items-center justify-center">
                        <AddUser onClose={handleClose} setIsAddUserVisible={setIsAddUserVisible} handleButtonClick={handleButtonClick} fetchData={fetchData} />

                    </div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {modalVisible && (
                    <div className="inset-0 z-50 flex items-center justify-center ">
                        <UpdateUser selectedUserData={selectedUserData} setSelectedUserData={setSelectedUserData} onClose={handleCloseModal} setModalVisible={setModalVisible} fetchData={fetchData} />
                    </div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {AddStaffModal && (
                    <div className="inset-0 z-50 flex items-center justify-center">

                        <AddStaff onClose={handleClose} setIsAddUserVisible={setIsAddUserVisible} handleButtonClick={handleButtonClick} fetchStaffData={fetchStaffData} setAddStaffModal={setAddStaffModal} />

                    </div>

                )

                }
            </AnimatePresence>
            <AnimatePresence>
                {updateStaffModal && (
                    <div className="inset-0 z-50 flex items-center justify-center ">

                        <UpdateStaff selectedUserData={selectedUserData} setSelectedUserData={setSelectedUserData} onClose={handleCloseModal} setModalVisible={setModalVisible} fetchStaffData={fetchStaffData} setUpdateStaffModal={setUpdateStaffModal} />
                    </div>

                )

                }
            </AnimatePresence>

        </div>
    );
};

export default UserListingPage;
