

import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { motion } from 'framer-motion';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UpdateStaff = ({ setIsAddStaffUpdate, staffData, setStaffData, fetchData }) => {
    const [errors, setErrors] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [stageDropdownOpen, setStageDropdownOpen] = useState(false);
    const [stageError, setStageError] = useState('');
    const [options, setOptions] = useState({
        stages: [],
        designations: []
    });

    const handleClose = () => {
        setIsAddStaffUpdate(false);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleStageToggle = () => {
        setStageDropdownOpen(!stageDropdownOpen);
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');

                setOptions({
                    stages: stagesResponse.data,
                    designations: designationsResponse.data,
                });
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStaffData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDesignationChange = (designation) => {
        setStaffData(prevData => ({
            ...prevData,
            designation: designation.id
        }));
        setIsOpen(false);
    };


    const handleStageChange = (stageId) => {
        if (!staffData.stages || !staffData.stages.some(stage => stage.id === stageId)) {
            const stageToAdd = options.stages.find(stage => stage.id === stageId);

            setStaffData(prevData => ({
                ...prevData,
                stages: [...(prevData.stages || []), stageToAdd]
            }));
        }

        setStageDropdownOpen(false);
    };




    const handleUpdate = async () => {
        try {
            const { id, first_name, phone, email, designation, stages } = staffData;


            const stageIds = stages.map(stage => stage.id.toString());

            const dataToUpdate = {
                id,
                first_name,
                phone,
                email,
                designation,
                stages: stageIds,
            };


            const response = await axiosInstance.put(`/account/api/v1/agency-staff-detail-view/${id}/`, dataToUpdate);

          



            if (response.status === 200) {
                fetchData();
                setIsAddStaffUpdate(false)


            }
        } catch (error) {
            console.error('Error updating staff:', error);
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
        }
    };





    const handleTagRemove = (stageId) => {

        if (staffData && staffData.stages) {
            const updatedStages = staffData.stages.filter(stage => stage.id !== stageId);
            setStaffData(prevData => ({
                ...prevData,
                stages: updatedStages
            }));
        }
    };




    return (
        <div className='fixed inset-0 z-50 rounded-md h-[100vh] overflow-y-auto'>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            />
            <motion.div
                className="p-6 z-30"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className="flex justify-center items-center py-10 fixed inset-0 z-50">
                    <div>
                        <div className="bg-white shadow-lg rounded-md">
                            <div className="bg-black">
                                <span className="text-2xl font-bold px-6 py-6 text-white flex justify-center items-center">
                                    Update Staff
                                </span>
                            </div>

                            <div className="px-6 pt-8">
                                <div className="flex gap-2 flex-col">
                                    <div className="flex gap-4 justify-between flex-col md:flex-row">
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="first_name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                value={staffData.first_name}
                                                onChange={handleInputChange}
                                                placeholder="Enter Name"
                                                className="border-none w-[270px] pt-sans-regular outline-none text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                            />
                                            {errors.first_name && <p className="text-red-500 text-xs">{errors.first_name}</p>}
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Phone Number
                                            </label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                value={staffData.phone}
                                                onChange={handleInputChange}
                                                placeholder="Enter Number"
                                                className="border-none w-[270px] pt-sans-regular outline-none text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                            />
                                            {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
                                        </div>
                                    </div>
                                    <div className="flex justify-between flex-col md:flex-row">
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={staffData.email}
                                                onChange={handleInputChange}
                                                placeholder="Enter Email"
                                                className="border-none w-[270px] pt-sans-regular outline-none text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                            />
                                            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                                        </div>
                                        <div className="space-y-2 relative">
                                            <label
                                                htmlFor="designation"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Designation
                                            </label>
                                            <div
                                                className="border-none w-[270px] pt-sans-regular outline-none text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                                onClick={handleToggle}
                                            >
                                                {staffData.designation}
                                            </div>
                                            {isOpen && (
                                                <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-60 overflow-auto">
                                                    {options.designations.map(option => (
                                                        <li
                                                            key={option.id}
                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                            onClick={() => handleDesignationChange(option.name)}
                                                        >
                                                            {option.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {errors.designation && <p className="text-red-500 text-xs">{errors.designation}</p>}
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="space-y-2 relative">
                                            <label htmlFor="stage" className="block text-sm font-medium text-gray-700">
                                                Stage
                                            </label>
                                            <div
                                                className="border-none w-[270px] pt-sans-regular outline-none text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                                onClick={() => setStageDropdownOpen(!stageDropdownOpen)}
                                            >
                                                Select Stage
                                            </div>
                                            {stageDropdownOpen && (
                                                <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-32 w-[270px] overflow-auto">
                                                    {options.stages.map(option => (
                                                        <li
                                                            key={option.id}
                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                            onClick={() => handleStageChange(option.id)}
                                                        >
                                                            <div className='flex gap-2 items-center'>
                                                                <div style={{
                                                                    backgroundColor: `#${option.
                                                                        bg_color
                                                                        }`,
                                                                }} className='w-2 h-2 rounded-full'></div>
                                                                <div className='text-[12px] font-medium'>{option.name}</div>

                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {errors.stage && <p className="text-red-500 text-xs">{errors.stage}</p>}
                                            {stageError && <p className="text-red-500 text-xs">{stageError}</p>}

                                            <div className="flex flex-wrap gap-2 mt-2">
                                                {staffData && staffData.stages && staffData.stages.map((stage, index) => (
                                                    <div className="flex items-center justify-between" key={index}>
                                                        <div
                                                            className="flex items-center px-3 py-1 rounded-md text-sm w-32"
                                                            style={{ backgroundColor: `#${stage.bg_color}`, color: `#${stage.color_code}` }}
                                                        >
                                                            {stage.name}
                                                        </div>
                                                        <div className="ml-2 text-red-500 cursor-pointer" onClick={() => handleTagRemove(stage.id)}>
                                                            <DeleteForeverIcon style={{ color: 'red' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center md:justify-end py-6 pt-20 gap-4">
                                    <button
                                        type="button"
                                        onClick={handleUpdate}
                                        className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default UpdateStaff;

