
import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import AgentStaffListingPage from './AgentStaffListingPage';



const AgentStaffSide = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [isOpenStage, setIsOpenStage] = useState(false);


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [menus, setMenu] = useState(false);




    const handleStageSelection = (stage) => {
        setSelectedStage(stage);
        setIsOpenStage(false);
    };


    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await axiosInstance.get('account/api/v1/module_menus/');
                setMenu(response.data);
            } catch (error) {
                console.error('Error fetching icons:', error);
                setError(error);
            }
        };

        fetchIcons();
    }, []);





    return (
        <div className='relative'>

            <div className='relative bg-white w-full z-10 '>
                <Header

                    handleStageSelection={handleStageSelection}
                    selectedAgency={selectedAgency}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setIsOpenStage={setIsOpenStage}
                    isOpenStage={isOpenStage}
                    selectedStage={selectedStage}
                    searchQuery={searchQuery}
                    menus={menus}
                />
            </div>
            <hr />
            <div className='flex  w-full justify-between '>
                <div className='pt-4'>
                    <SideBar

                        menus={menus}

                    />
                </div>

               
                  
                    <div className='flex justify-center pt-20  flex-grow'>
                        <AgentStaffListingPage  menus={ menus}/>
                    </div>

                



            </div>
        </div>
    );
};

export default AgentStaffSide;